import React, { useState } from 'react';
import DemoPlayer from './DemoPlayer';
import { useAuth } from '@clerk/clerk-react';
import './TTSBox.css';

const TTSBox = () => {
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { getToken } = useAuth();

  const handleTextChange = (e) => {
    setInputText(e.target.value);
    setError(null);
  };

  const handleTTSRequest = async (text, voice = 'alloy') => {
    if (!text.trim()) {
      setError('Please enter some text');
      return null;
    }

    setIsProcessing(true);
    setError(null);
    
    try {
      const token = await getToken();
      
      const response = await fetch('http://localhost:8000/tts/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify({
          text: text,
          voice: voice,
          model: 'tts-1',
          speed: 1.0,
          deviceUUID: localStorage.getItem('deviceUUID') || null,
          clerkToken: token || null
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to process text');
      }

      return response;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="premium-tts-box">
      <h2>Text to Speech</h2>
      <div className="tts-container">
        <div className="input-wrapper">
          <textarea
            className="tts-input"
            placeholder="Type or paste your text here..."
            value={inputText}
            onChange={handleTextChange}
            disabled={isProcessing}
          />
          {error && <div className="error-message">{error}</div>}
        </div>
        <div className="player-container">
          <DemoPlayer 
            customText={inputText} 
            onTTSRequest={handleTTSRequest}
            isProcessing={isProcessing}
          />
        </div>
      </div>
    </div>
  );
};

export default TTSBox;
