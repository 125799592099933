'use client'

import React, { useState, useEffect, useCallback } from 'react';
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useClerk,
  useUser,
  useAuth,
} from '@clerk/clerk-react';
import { Routes, Route, useLocation } from 'react-router-dom';
/*import SubscriptionSection from './SubscriptionSection';
*/
import {
  ChevronDown,
  ChevronUp,
  Menu,
  
  Mail,
  // VolumeX,
  // Maximize2,
  // Minimize2,
} from 'lucide-react';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import './App.css';
import SuccessPage from './SuccessPage';
import DemoPlayer from './components/DemoPlayer';
import TTSBox from './components/TTSBox';
// Import all video files from the videos folder
//import video1 from './assets/videos/video1.mp4';
//import video2 from './assets/videos/video2.mp4';
//import video3 from './assets/videos/video3.mp4';
// Add more video imports as needed

// Remove these imports and constants
// import { getVideoUrl } from './config/constants';
// const videoFiles = [
//   getVideoUrl('video1.mp4'),
//   getVideoUrl('video2.mp4'),
//   getVideoUrl('video3.mp4')
// ];

const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!publishableKey) {
  throw new Error('Missing Publishable Key');
}

const features = [
  'Transform any website into audiobook',
  'Your personal narrator',
  'Instant summary of the content',
  'Better understanding',
  'Efficient consumption and multitasking',
  'To say to friends "I heard that.." about any content',
];



const howItWorks = [
  { title: 'Install Extension', description: 'Add USETHEVOICE to your browser with one click' },
  { title: 'Select Content', description: 'Select the text or click play button for full-page reading' },
  { title: 'Choose Mode', description: 'Pick between full text narration or summary playback' },
  { title: 'Listen and Learn', description: 'Enjoy your content narrated by AI voices' },
];

const HowItWorksStep = ({ title, description }) => (
  <div className="how-it-works-step">
    <h3 className="how-it-works-title">{title}</h3>
    <p className="how-it-works-description">{description}</p>
  </div>
);

const testimonials = [
  {
    name: 'William Shakespeare',
    quote: 'To listen, or not to listen: that is no longer the question with USETHEVOICE!',
  },
  {
    name: 'Leonardo da Vinci',
    quote: 'Had I USETHEVOICE, I could have painted the Mona Lisa while learning about flying machines or listening to Joe Rogan..',
  },
  {
    name: 'Ludwig van Beethoven',
    quote: 'If I had USETHEVOICE, well I dont think I would use it, as I am deaf, you know, but I am sure it is great.. if you say so.',
  },
];

const Testimonial = ({ name, quote }) => (
  <motion.div className="testimonial" whileHover={{ y: -5 }}>
    <p className="testimonial-quote">"{quote}"</p>
    <p className="testimonial-author">
      - {name}
    </p>
  </motion.div>
);

const faqData = [
  {
    question: 'Is USETHEVOICE available as an app?',
    answer:
      "Not yet, we're working on it!",
  },
  {
    question: 'How accurate are the AI-generated summaries?',
    answer:
      'Our AI summaries are highly accurate, capturing the main points of articles with over 95% accuracy. This helps you save time and get the gist quickly.',
  },
  {
    question: 'Can I use USETHEVOICE offline?',
    answer:
      'No, but you can access the history of your listening in the app.',
  },
  {
    question: 'How many voices are available?',
    answer:
      'We offer 6 voices. We are planning to add more in the future.',
  },
  {
    question: 'Is there a limit to how much content I can listen to?',
    answer:
      'The amount of content you can listen to depends on your plan. We offer options ranging from our free tier to unlimited listening for power users.',
  },
];

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button
        className="faq-question"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        {isOpen ? (
          <ChevronUp className="faq-icon" />
        ) : (
          <ChevronDown className="faq-icon" />
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.p
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="faq-answer"
          >
            {answer}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

// Add this after your sampleTexts constant
const demoNames = ['Meditations', 'The_Prince', 'Tale_of_Two_Cities'];

const DEMO_TIMELINES = {
  'Meditations': [
    {
      sentence: "Begin the morning by saying to yourself, I shall meet with the busy-body, the ungrateful, arrogant, deceitful, envious, unsocial.",
      startTime: 0,
      endTime: 8
    },
    {
      sentence: "All these things happen to them by reason of their ignorance of what is good and evil.",
      startTime: 9,
      endTime: 14
    },
    {
      sentence: "But I who have seen the nature of the good that it is beautiful, and of the bad that it is ugly, and of the erring, that it is akin to me, not only of the same blood or seed, but that it participates in the same intelligence and the same portion of divinity.",
      startTime: 14,
      endTime: 27
    },
    {
      sentence: "I can neither be injured by any of them, for no one can fix on me what is ugly, nor can I be angry with my kinsman, nor hate him.",
      startTime: 28,
      endTime: 37
    }
  ],
  'The_Prince': [
    {
      sentence: "All states, all powers, that have held and hold rule over men have been and are either republics or principalities.",
      startTime: 0,
      endTime: 7
    },
    {
      sentence: "Principalities are either hereditary, in which the family has been long established; or they are new.",
      startTime: 7,
      endTime: 13
    },
    {
      sentence: "The new are either entirely new, as was Milan to Francesco Sforza, or they are, as it were, members annexed to the hereditary state of the prince who has acquired them, as was the kingdom of Naples to that of the King of Spain.",
      startTime: 13,
      endTime: 27
    },
    {
      sentence: "Such dominions thus acquired are either accustomed to live under a prince, or to live in freedom; and are acquired either by the arms of the prince himself, or of others, or else by fortune or by ability.",
      startTime: 27,
      endTime: 40
    }
  ],
  'Tale_of_Two_Cities': [
    {
      sentence: "It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair, we had everything before us, we had nothing before us, we were all going direct to Heaven, we were all going direct the other way.",
      startTime: 0,
      endTime: 25
    },
    {
      sentence: "In short, the period was so far like the present period, that some of its noisiest authorities insisted on its being received, for good or for evil, in the superlative degree of comparison only.",
      startTime: 26,
      endTime: 37
    }
  ]
};

// Add this before the App component
const sampleTexts = [
  {
    title: "Meditations",
    text: `Begin the morning by saying to yourself, I shall meet with the busy-body, the ungrateful, arrogant, deceitful, envious, unsocial. All these things happen to them by reason of their ignorance of what is good and evil. But I who have seen the nature of the good that it is beautiful, and of the bad that it is ugly, and of the erring, that it is akin to me, not only of the same blood or seed, but that it participates in the same intelligence and the same portion of divinity, I can neither be injured by any of them, for no one can fix on me what is ugly, nor can I be angry with my kinsman, nor hate him.`
  },
  {
    title: "The Prince",
    text: `All states, all powers, that have held and hold rule over men have been and are either republics or principalities. Principalities are either hereditary, in which the family has been long established; or they are new. The new are either entirely new, as was Milan to Francesco Sforza, or they are, as it were, members annexed to the hereditary state of the prince who has acquired them, as was the kingdom of Naples to that of the King of Spain. Such dominions thus acquired are either accustomed to live under a prince, or to live in freedom; and are acquired either by the arms of the prince himself, or of others, or else by fortune or by ability.`
  },
  {
    title: "A Tale of Two Cities",
    text: `It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair, we had everything before us, we had nothing before us, we were all going direct to Heaven, we were all going direct the other way—in short, the period was so far like the present period, that some of its noisiest authorities insisted on its being received, for good or for evil, in the superlative degree of comparison only.`
  }
];



const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { openSignIn } = useClerk();
  const { isLoaded, isSignedIn, user } = useUser();
  const location = useLocation();
  const { getToken } = useAuth();
  const [shouldOpenSignIn, setShouldOpenSignIn] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [isPlansExpanded, setIsPlansExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  // Also add the useEffect to fetch subscription info
  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      if (!isLoaded || !isSignedIn) return;

      try {
        const token = await getToken();
        const response = await fetch('https://usethevoice.com/api/get_user_info_react_subscription', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ clerkUserId: user.id }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch subscription info');
        }

        const data = await response.json();
        setSubscriptionInfo(data);
      } catch (error) {
        console.error('Error fetching subscription info:', error);
      }
    };

    fetchSubscriptionInfo();
  }, [isLoaded, isSignedIn, getToken, user]);

  const [deviceUUID, setdeviceUUID] = useState(() => {
    return sessionStorage.getItem('deviceUUID') || null;
  });
  useEffect(() => {
    if (!isLoaded) {
      // Wait until Clerk has loaded
      return;
    }
    const params = new URLSearchParams(location.search);
    const uuid = params.get('uuid');
    if (uuid) {
      console.log('UUID in react app received:', uuid);
      setdeviceUUID(uuid);
      sessionStorage.setItem('deviceUUID', uuid);
      if (!isSignedIn) {
        setShouldOpenSignIn(true);
      }
    }
  }, [location.search, isLoaded, isSignedIn]);

  useEffect(() => {
    if (!isLoaded) {
      // Wait until Clerk has loaded
      return;
    }
    if (shouldOpenSignIn && !isSignedIn) {
      openSignIn();
      setShouldOpenSignIn(false);
    }
  }, [shouldOpenSignIn, isLoaded, isSignedIn, openSignIn]);


  const associateUUIDWithClerkUser = useCallback(
    async (uuid, clerkUserId) => {
      try {
        const token = await getToken(); // Get Clerk JWT
  
        const response = await fetch('https://usethevoice.com/api/associate_uuid', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ uuid, clerkUserId }),
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Backend responded with error:', errorText);
          throw new Error('Failed to associate UUID with Clerk user');
        }
  
        // Optionally, handle any data returned from the backend
      } catch (error) {
        console.error('Error associating UUID with Clerk user:', error);
      }
    },
    [getToken] // Include getToken in the dependency array
  );

  useEffect(() => {
    // If the user is signed in and we have the deviceUUID, proceed to associate
    if (isSignedIn && deviceUUID && user && !shouldOpenSignIn) {
      console.log('Attempting to associate UUID with Clerk user');
      associateUUIDWithClerkUser(deviceUUID, user.id).then(() => {
        // Clear deviceUUID from sessionStorage after successful association
        sessionStorage.removeItem('deviceUUID');
        setdeviceUUID(null);
        
        console.log('UUID successfully associated with Clerk user');
      }).catch(error => {
        console.error('Failed to associate UUID:', error);
      });
    }
  }, [isSignedIn, deviceUUID, user, associateUUIDWithClerkUser, shouldOpenSignIn]);

  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);

  const handleCheckout = async (planName) => {
    if (!isSignedIn) {
      openSignIn();
      return;
    }

    try {
      const token = await getToken();
      const response = await fetch('https://usethevoice.com/api/create_checkout_session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          clerkUserId: user.id,
          planName: planName,
          successUrl: `${window.location.origin}/success`,
          cancelUrl: `${window.location.origin}/#pricing`,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { sessionUrl } = await response.json();
      window.location.href = sessionUrl;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  // Add this function near your other handlers
  const handleManageSubscription = async () => {
    try {
      const token = await getToken();
      const response = await fetch('https://usethevoice.com/api/create_customer_portal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ clerkUserId: user.id }),
      });

      if (!response.ok) {
        throw new Error('Failed to create customer portal session');
      }

      const { url } = await response.json();
      if (url) {
        window.location.href = url;
      }
    } catch (error) {
      console.error('Error creating customer portal session:', error);
    }
  };

  useEffect(() => {
    // Check if there's a hash in the URL
    if (window.location.hash === '#pricing') {
      // Find the pricing section and scroll to it
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []); // Empty dependency array means this runs once on mount

  

  const handleTabChange = (index) => {
    setActiveTab(index);
    // Map the tab index to the corresponding demo name
    const demoNames = ['Meditations', 'The_Prince', 'Tale_of_Two_Cities'];
    const selectedDemo = demoNames[index];
    
    console.log('Triggering demo change:', selectedDemo); // Debug log
    
    // Create and dispatch the custom event
    const event = new CustomEvent('DEMO_TAB_CHANGE', {
      detail: { demo: selectedDemo }
    });
    document.dispatchEvent(event);
  };

  const shouldShowFullContent = !isSignedIn || 
    (subscriptionInfo?.subscriptionName.toLowerCase() === 'free');

  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={
          <>
            <motion.header className="header" style={{ opacity }}>
              <div className="header-content">
                <div className="logo">USE<span>THE</span>VOICE</div>
                <nav className="nav-links">
                  {shouldShowFullContent && (
                    <>
                      <a href="#features" className="nav-link">Features</a>
                      <a href="#how-it-works" className="nav-link">How It Works</a>
                    </>
                  )}
                  <a href="#pricing" className="nav-link">Pricing</a>
                  <a href="#faq" className="nav-link">FAQ</a>
                </nav>
                <div className="auth-buttons">
                  <SignedOut>
                    <button
                      className="btn btn-primary" 
                      style={{
                        background: 'black',
                        color: 'white',
                        border: 'none',
                        transition: 'filter 0.2s, transform 0.2s',
                      }}
                      onClick={() => openSignIn()}
                    >
                      Sign In
                    </button>
                  </SignedOut>
                  <SignedIn>
                    {/* we need this div from reloading the window when video is playing */}
                  <div> 

                    <UserButton>
                     
                      <UserButton.UserProfilePage label="account" />

                      {/*
                       <UserButton.UserProfilePage 
                        label="Subscription" 
                        url="subscription" 
                        labelIcon={<svg
                          xmlns="https://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          fill="currentColor"
                          style={{ width: '24px', height: '24px' }}
                        >
                          <path d="M256 0C114.833 0 0 114.833 0 256s114.833 256 256 256 256-114.833 256-256S397.167 0 256 0zm0 482C132.288 482 30 379.712 30 256S132.288 30 256 30s226 102.288 226 226-102.288 226-226 226z" />
                          <path d="M377.5 181.25l-153.75 153.75-71.25-71.25-21.213 21.213 92.463 92.463 175-175z" />
                        </svg>}
                      >
                        <SubscriptionSection />

                      </UserButton.UserProfilePage>
                      */}
                      <UserButton.UserProfilePage label="security" />
                    </UserButton>
                    </div>
                  </SignedIn>
                  <button
                    className="menu-button"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    <Menu className="menu-icon" />
                  </button>
                </div>
              </div>
              
              <AnimatePresence>
                {isMenuOpen && (
                  <motion.nav
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="mobile-nav"
                  >
                    <a href="#features" className="mobile-nav-link">
                      Features
                    </a>
                    <a href="#how-it-works" className="mobile-nav-link">
                      How It Works
                    </a>
                    <a href="#pricing" className="mobile-nav-link">
                      Pricing
                    </a>
                    <a href="#faq" className="mobile-nav-link">
                      FAQ
                    </a>
                  </motion.nav>
                )}
              </AnimatePresence>
            </motion.header>
            

            <main className="main-content">
              {shouldShowFullContent ? (
                // Show all sections for non-logged in and free users
                <>
                  <section className="hero-section">
                    <div className="hero-content">
                      <div className="hero-text">
                        <motion.h1
                          className="hero-title"
                          initial={{ y: 50, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.8, delay: 0.2 }}
                        >
                          Listen to your web
                        </motion.h1>
                        <motion.p
                          className="hero-subtitle"
                          initial={{ y: 50, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.8, delay: 0.4 }}
                        >
                          Transform any web content into audio with this extension.
                        </motion.p>

                        {/* Add demo section here, before the button */}
                        <motion.div
                          className="demo-box"
                          initial={{ y: 50, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.8, delay: 0.5 }}
                        >
                          <div className="demo-tabs">
                            {sampleTexts.map((sample, index) => (
                              <button
                                key={index}
                                className={`demo-tab ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabChange(index)}
                              >
                                {sample.title}
                              </button>
                            ))}
                          </div>
                          <div className="demo-content">
                            <h3>{sampleTexts[activeTab].title}</h3>
                            <div className="demo-text">
                              {DEMO_TIMELINES[demoNames[activeTab]]?.map((entry, index) => (
                                <span
                                  key={index}
                                  className="demo-sentence"
                                >
                                  {entry.sentence}{' '}
                                </span>
                              ))}
                            </div>
                            <DemoPlayer 
  currentExample={demoNames[activeTab]}
                              timeline={DEMO_TIMELINES[demoNames[activeTab]]}
                            />
                          </div>
                        </motion.div>

                        <motion.div
                          initial={{ y: 50, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.8, delay: 0.6 }}
                        >
                          <SignInButton mode="modal">
                            <button className="btn btn-primary">
                              Start Listening Now
                            </button>
                          </SignInButton>
                        </motion.div>
                      </div>
                    </div>
                  </section>

                  <section id="features" className="features-section">
                    <div className="section-container">
                      <h2 className="section-title">Why USETHEVOICE</h2>
                      <motion.ul 
                        className="features-list"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: true }}
                      >
                        {features.map((feature, index) => (
                          <motion.li 
                            key={index}
                            className="feature-item"
                            initial={{ opacity: 0, x: -20 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                          >
                            {feature}
                          </motion.li>
                        ))}
                      </motion.ul>
                    </div>
                  </section>

                  <section id="how-it-works" className="how-it-works-section">
                    <div className="section-container">
                      <h2 className="section-title">How USETHEVOICE</h2>
                      <div className="how-it-works-steps">
                        {howItWorks.map((step, index) => (
                          <HowItWorksStep key={index} {...step} />
                        ))}
                      </div>
                    </div>
                  </section>

                  <section className="testimonials-section">
                    <div className="section-container">
                      <h2 className="section-title">What They Would Have Said</h2>
                      <motion.div 
                        className="testimonials-grid"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.8, staggerChildren: 0.2 }}
                        viewport={{ once: true }}
                      >
                        {testimonials.map((testimonial, index) => (
                          <Testimonial key={index} {...testimonial} />
                        ))}
                      </motion.div>
                    </div>
                  </section>
                </>
              ) : null}

              {/* Always show pricing and FAQ sections */}
              <section id="pricing" className="pricing-section">
                <div className="section-container">
                  {/* Add TTSBox here, before the pricing title */}
                  {isSignedIn && subscriptionInfo?.subscriptionName.toLowerCase() !== 'free' && (
                    <TTSBox />
                  )}
                  
                  <h2 className="section-title">
                    {isSignedIn && subscriptionInfo?.subscriptionName.toLowerCase() !== 'free'
                      ? 'Current Plan'
                      : 'Choose Your Plan'}
                  </h2>

                  {/* Current Plan Info - Updated styling */}
                  {isSignedIn && subscriptionInfo && (
                    <div className="current-plan-info">
                      <h3>Plan: {subscriptionInfo.subscriptionName.toUpperCase()}</h3>
                      <p>Usage: {subscriptionInfo.usageAmount.toLocaleString()} / {subscriptionInfo.usageLimit.toLocaleString()} characters</p>
                      
                      {/* Pretty scale with gradient */}
                      <div className="usage-scale">
                        <div 
                          className="usage-progress"
                          style={{ 
                            width: `${Math.min((subscriptionInfo.usageAmount / subscriptionInfo.usageLimit) * 100, 100)}%`,
                            background: subscriptionInfo.subscriptionName.toLowerCase() === 'basic' 
                              ? 'linear-gradient(90deg, #4A90E2 0%, #63B3ED 100%)'
                              : 'linear-gradient(90deg, #9B51E0 0%, #B794F4 100%)'
                          }}
                        />
                      </div>

                      {/* Renewal/Cancellation info */}
                      <p className="renewal-info">
                        {subscriptionInfo.status === 'canceled' 
                          ? `Subscription canceled but active until: ${
                              new Date(subscriptionInfo.subscriptionEndsAt).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              })
                            }`
                          : `Credits will renew on: ${
                              new Date(subscriptionInfo.subscriptionEndsAt).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                              })
                            }`
                        }
                      </p>

                      {/* Change Plan button for paid users */}
                      {subscriptionInfo.subscriptionName.toLowerCase() !== 'free' && (
                        <button 
                          onClick={handleManageSubscription}
                          className="plan-button plan-button-black mt-4"
                        >
                          Change Plan
                        </button>
                      )}
                    </div>
                  )}

                  {/* Pricing Cards Section - Modified logic */}
                  {(subscriptionInfo?.subscriptionName.toLowerCase() === 'free' || !isSignedIn) ? (
                    // Show all cards for free users and non-logged in users
                    <div className="pricing-plans">
                      {[
                        {
                          title: 'Free',
                          price: '$0/month',
                          features: [
                            '2,000 characters per day',
                            '6 natural voices',
                            'Text-to-speech',
                            'Summaries',
                          ],
                          buttonClass: 'plan-button-black',
                          planName: 'free',
                        },
                        {
                          title: 'Basic',
                          price: '$5.99/month',
                          features: [
                            '200,000 characters/month',
                            'Everything in free +',
                            'more features coming soon',
                          ],
                          buttonClass: 'plan-button-black',
                          planName: 'basic',
                        },
                        {
                          title: 'Plus',
                          price: '$11.99/month',
                          features: [
                            '800,000 characters/month',
                            'Everything in basic +',
                            'more features coming soon',
                            'priority support',
                          ],
                          featured: true,
                          buttonClass: 'plan-button-gradient',
                          planName: 'plus',
                        },
                      ].map((plan, index) => (
                        <motion.div
                          key={index}
                          className={`pricing-card ${plan.featured ? 'featured' : ''}`}
                        >
                          <div>
                            <h3 className="plan-title">{plan.title}</h3>
                            <p className="plan-price">{plan.price}</p>
                            <ul className="plan-features">
                              {plan.features.map((feature, i) => (
                                <li key={i} className="plan-feature">
                                  <svg
                                    className="feature-icon-check"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="https://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M5 13l4 4L19 7"
                                    ></path>
                                  </svg>
                                  {feature}
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* Show appropriate button based on plan and sign-in status */}
                          {(!isSignedIn || plan.title.toLowerCase() !== 'free') && (
                            <button 
                              className={`plan-button ${plan.buttonClass}`}
                              onClick={() =>
                                !isSignedIn
                                  ? openSignIn()
                                  : plan.title.toLowerCase() !== 'free'
                                    ? handleCheckout(plan.planName)
                                    : openSignIn()
                              }
                            >
                              {!isSignedIn 
                                ? (plan.title.toLowerCase() === 'free' ? 'Start Now' : 'Subscribe')
                                : 'Upgrade'}
                            </button>
                          )}
                        </motion.div>
                      ))}
                    </div>
                  ) : (
                    // Show collapsed cards without buttons for paid users
                    <div className="pricing-plans-collapsible">
                      <button 
                        className="expand-plans-button"
                        onClick={() => setIsPlansExpanded(!isPlansExpanded)}
                      >
                        {isPlansExpanded ? (
                          <>Hide All Plans <ChevronUp className="chevron-icon" /></>
                        ) : (
                          <>View All Plans <ChevronDown className="chevron-icon" /></>
                        )}
                      </button>
                      
                      {isPlansExpanded && (
                        <motion.div 
                          className="pricing-plans"
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          {[
                            {
                              title: 'Free',
                              price: '$0/month',
                              features: [
                                '2,000 characters per day',
                                '6 natural voices',
                                'Text-to-speech',
                                'Summaries',
                              ],
                              buttonClass: 'plan-button-black',
                              planName: 'free',
                            },
                            {
                              title: 'Basic',
                              price: '$5.99/month',
                              features: [
                                '200,000 characters/month',
                                'Everything in free +',
                                'more features coming soon',
                              ],
                              buttonClass: 'plan-button-black',
                              planName: 'basic',
                            },
                            {
                              title: 'Plus',
                              price: '$11.99/month',
                              features: [
                                '800,000 characters/month',
                                'Everything in basic +',
                                'more features coming soon',
                              ],
                              featured: true,
                              buttonClass: 'plan-button-gradient',
                              planName: 'plus',
                            },
                          ].map((plan, index) => (
                            <motion.div
                              key={index}
                              className={`pricing-card ${plan.featured ? 'featured' : ''} 
                                ${subscriptionInfo?.subscriptionName.toLowerCase() === plan.title.toLowerCase() ? 'current-plan' : ''}`}
                            >
                              <div>
                                <h3 className="plan-title">{plan.title}</h3>
                                <p className="plan-price">{plan.price}</p>
                                <ul className="plan-features">
                                  {plan.features.map((feature, i) => (
                                    <li key={i} className="plan-feature">
                                      <svg
                                        className="feature-icon-check"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="https://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M5 13l4 4L19 7"
                                        ></path>
                                      </svg>
                                      {feature}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              {/* No buttons in collapsed view for paid users */}
                            </motion.div>
                          ))}
                        </motion.div>
                      )}
                    </div>
                  )}
                </div>
              </section>

              <section id="faq" className="faq-section">
                <div className="section-container">
                  <h2 className="section-title">Frequently Asked Questions</h2>
                  <motion.div 
                    className="faq-container"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.8, staggerChildren: 0.1 }}
                    viewport={{ once: true }}
                  >
                    {faqData.map((item, index) => (
                      <FAQItem key={index} {...item} />
                    ))}
                  </motion.div>
                </div>
              </section>
            </main>
            
            <footer className="footer">
              <div className="footer-container">
                <div className="footer-grid">
                  <div className="footer-about">
                    <h3 className="footer-title">USETHEVOICE</h3>
                    <p className="footer-text">
                      Transform your web experience with AI-powered audio content.
                    </p>
                  </div>
                  <div className="footer-links">
                    <h4 className="footer-subtitle">Quick Links</h4>
                    <ul>
                      <li><a href="#features" className="footer-link">Features</a></li>
                      <li><a href="#how-it-works" className="footer-link">How It Works</a></li>
                      <li><a href="#pricing" className="footer-link">Pricing</a></li>
                      <li><a href="#faq" className="footer-link">FAQ</a></li>
                    </ul>
                  </div>
                  <div className="footer-connect">
                    <h4 className="footer-subtitle">Connect</h4>
                    <a href="mailto:info@usethevoice.com" className="footer-link footer-email">
                      <Mail className="footer-email-icon" />
                      info@usethevoice.com
                    </a>
                  </div>
                </div>
                <div className="footer-bottom">
                  <p>&copy; 2024 USETHEVOICE. All rights reserved.</p>
                </div>
              </div>
            </footer>
          </>
        } />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </div>
);
};

export default App;
